// VARIABLES DE B5
$body-bg: #ececec;
// Configuration
// @import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// Layout & components
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
// @import "~bootstrap/scss/tables";
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
// @import '~bootstrap/scss/dropdown';
// @import '~bootstrap/scss/button-group';
// @import '~bootstrap/scss/nav';
// @import '~bootstrap/scss/navbar';
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/accordion";
// @import '~bootstrap/scss/breadcrumb';
// @import '~bootstrap/scss/pagination';
// @import '~bootstrap/scss/badge';
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
@import '~bootstrap/scss/list-group';
// @import '~bootstrap/scss/close';
// @import "~bootstrap/scss/toasts";
@import '~bootstrap/scss/modal';
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import '~bootstrap/scss/carousel';
// @import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";

@import '~bootstrap/scss/helpers';

@import '~bootstrap/scss/utilities/api';

// @import '~animate.css/animate.css';

.menuSelect {
  z-index: 999999999;
  .content {
    margin-bottom: -100%;
    background-color: #fbfbfb;
    .btn-light {
      &:hover {
        background: #0dcaf0;
        color: #fff;
      }
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #dadada;
  border-top: 10px solid #198754;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.textPasos {
  margin-top: 1rem;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.containerChar {
  background: #fff;
  .labels {
    background: #ececec;
    .charLabel {
      cursor: pointer;
      border-radius: 0;
      border: 0;
      border-right: 3px solid #ececec;
      label,
      .porcentaje {
        font-size: 10px;
      }
      .charColor {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        &::before {
          content: '';
        }
      }
      .number {
        font-size: 14px;
      }
    }
  }
}
.visible {
  visibility: visible;
}
.hiden {
  visibility: hidden;
}
.progress-bar {
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}
.apexcharts-legend-series {
  margin: 0 !important;
  padding-top: 5px;
  border-style: solid;
  border-color: transparent;
  border-width: 0px 0px 2px 0px;
  margin-right: 20px !important;
  .apexcharts-legend-marker {
    display: none;
  }
  &:hover {
    border-bottom: 2px solid rgb(5, 114, 206);
  }
  .apexcharts-legend-text {
    text-align: left;
    padding: 0 0 0 0;
    .legend-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 80px;
      padding-top: 5px;
      .number {
        font-size: 18px;
        margin-right: 15px;
      }
      .porciento-up {
        color: #20bb06;
        font-weight: bold;
        &::before {
          content: '▲';
        }
        &::after {
          content: ' %';
        }
      }
      .porciento-dow {
        color: rgb(255, 9, 9);
        &::before {
          content: '▼';
        }
        &::after {
          content: ' %';
        }
      }
    }
  }
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
.containerIMG {
  .img-change {
    padding: 15px;
  }
}
